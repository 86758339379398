import {
  Firestore,
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useState } from "react";

import ConfirmData from "./ConfirmData";
import { authClasses } from "../../../pages/Auth/authClasses";
import { db } from "../../../firebase";

const RegisterPopup = ({
  athlete,
  evnt,
  setRegisterEvent,
  addEventRegistration,
}) => {
  const { buttonSmall } = authClasses;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [gradYear, setGradYear] = useState(athlete.gradYear ?? "HS Grad Year");
  const [school, setSchool] = useState(athlete.school ?? undefined);
  const [month, setMonth] = useState(
    athlete.dob !== undefined
      ? athlete.dob.toDate().toLocaleString("default", { month: "long" })
      : "Month"
  );
  const [day, setDay] = useState(
    athlete.dob !== undefined ? athlete.dob.toDate().getDate() : "Day"
  );
  const [year, setYear] = useState(
    athlete.dob !== undefined ? athlete.dob.toDate().getFullYear() : "Year"
  );
  const [inches, setInches] = useState(athlete.inches ?? "Height (in)");
  const [feet, setFeet] = useState(athlete.feet ?? "Height (ft)");
  const [shirtSize, setShirtSize] = useState(athlete.shirtSize ?? "Shirt Size");
  const [shortSize, setShortSize] = useState(
    athlete.shortSize ?? "Shorts Size"
  );

  const handleRegister = async () => {
    setLoading(true);
    const colRef = collection(db, "registrations");

    const athRef = doc(db, `users/${athlete.parentID}/athletes/${athlete.id}`);
    updateDoc(athRef, {
      gradYear,
      school,
      inches,
      feet,
      dob: new Date(`${month} ${day}, ${year}`),
      shirtSize,
      shortSize,
    });

    addDoc(
      colRef,
      evnt.cost === "0"
        ? {
            athlete: athlete.id,
            event: evnt.id,
            status: "paid",
            paidAt: serverTimestamp(),
            parentID: athlete.parentID,
          }
        : {
            athlete: athlete.id,
            event: evnt.id,
            status: "unpaid",
            parentID: athlete.parentID,
          }
    )
      .then((ref) => {
        setLoading(false);
        setRegisterEvent(null);
        addEventRegistration(
          evnt.id,
          athlete.id,
          ref.id,
          evnt.cost === "0" ? "paid" : "unpaid"
        );
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        top: "0",
        left: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.7)",
      }}
    >
      <div
        style={{
          width: "70vw",
          backgroundColor: "rgba(0,0,0,0.9)",
          display: "flex",
          borderRadius: "16px",
          padding: "16px",
          flexDirection: "column",
        }}
      >
        {error ? (
          <div>
            <h4 style={{ margin: "4px 0" }}>Error Registering for Event</h4>
            <h6 style={{ margin: "4px 0" }}>
              Email director@sidewinderva.com if you keep gettting this issue.
            </h6>
            <small>{error}</small>
            <button
              className={buttonSmall}
              onClick={() => {
                setError(null);
                setRegisterEvent(null);
              }}
            >
              Ok
            </button>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5 style={{ margin: "8px 0" }}>Register for Event</h5>
            <small style={{ color: "#CCC" }}>
              Athlete:{" "}
              <strong>
                {athlete.firstName} {athlete.lastName}
              </strong>
            </small>
            <small style={{ color: "#CCC" }}>
              Event:{" "}
              <strong>
                {evnt.title} - {evnt.dateString}
              </strong>
            </small>
            {evnt.type !== "Tryout" ? (
              <small style={{ margin: "8px 0", color: "#CCC" }}>
                {evnt.description}
              </small>
            ) : null}
            <ConfirmData
              school={school}
              setSchool={setSchool}
              gradYear={gradYear}
              setGradYear={setGradYear}
              month={month}
              setMonth={setMonth}
              day={day}
              setDay={setDay}
              year={year}
              setYear={setYear}
              feet={feet}
              setFeet={setFeet}
              inches={inches}
              setInches={setInches}
              season={athlete.season}
              shortSize={shortSize}
              setShortSize={setShortSize}
              shirtSize={shirtSize}
              setShirtSize={setShirtSize}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "8px 0",
              }}
            >
              <button
                className={buttonSmall}
                style={{
                  backgroundColor: "gray",
                  opacity: loading ? 0.2 : 1,
                }}
                disabled={loading}
                onClick={() => setRegisterEvent(null)}
              >
                Cancel
              </button>
              <button
                className={buttonSmall}
                style={{
                  backgroundColor: "rgb(50, 150, 50)",
                  opacity:
                    loading ||
                    school === undefined ||
                    isNaN(parseInt(gradYear)) ||
                    isNaN(parseInt(day)) ||
                    isNaN(parseInt(year)) ||
                    month === "Month" ||
                    isNaN(parseInt(feet)) ||
                    isNaN(parseInt(inches)) ||
                    shirtSize === "Shirt Size" ||
                    shortSize === "Shorts Size"
                      ? 0.2
                      : 1,
                }}
                onClick={() => handleRegister()}
                disabled={
                  loading ||
                  school === undefined ||
                  isNaN(parseInt(gradYear)) ||
                  isNaN(parseInt(day)) ||
                  isNaN(parseInt(year)) ||
                  month === "Month" ||
                  isNaN(parseInt(feet)) ||
                  isNaN(parseInt(inches)) ||
                  shirtSize === "Shirt Size" ||
                  shortSize === "Shorts Size"
                }
              >
                {loading ? "Registering..." : "Register"}
              </button>
            </div>
            {school === undefined ||
            isNaN(parseInt(gradYear)) ||
            isNaN(parseInt(day)) ||
            isNaN(parseInt(year)) ||
            month === "Month" ||
            isNaN(parseInt(feet)) ||
            isNaN(parseInt(inches)) ||
            shirtSize === "Shirt Size" ||
            shortSize === "Shorts Size" ? (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}></div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <small style={{ textAlign: "center" }}>
                    {school === undefined
                      ? "Missing School"
                      : isNaN(parseInt(gradYear))
                      ? "Missing Grad Year"
                      : isNaN(parseInt(day)) ||
                        isNaN(parseInt(year)) ||
                        month === "Month"
                      ? "Incomplete Date of Birth"
                      : isNaN(parseInt(feet)) || isNaN(parseInt(inches))
                      ? "Incomplete Height"
                      : shirtSize === "Shirt Size"
                      ? "Missing Shirt Size"
                      : shortSize === "Shorts Size"
                      ? "Missing Shorts Size"
                      : ""}
                  </small>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterPopup;
