import { FC, useState } from "react";

import { DocumentData } from "firebase/firestore";
import PayPopup from "../Events/PayPopup";
import RegisterPopup from "../Events/RegisterPopup";
import RegisteredEvents from "../Events/RegisteredEvents";
import UpcomingEvents from "../Events/UpcomingEvents";
import { User } from "../../../models/User";

type AthleteProps = {
  athlete: any;
  user: User;
  fetchAthletes: () => {};
  events: DocumentData[];
  addEventRegistration: (
    eventID: string,
    athleteID: string,
    regID: string,
    status: string
  ) => void;
};

const Athlete: FC<AthleteProps> = (props) => {
  const { athlete, events, addEventRegistration } = props;

  const [registerEvent, setRegisterEvent] = useState(null);
  const [payEvent, setPayEvent] = useState(null);

  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const birthYear =
    athlete.dob.toDate().getFullYear() +
    (athlete.dob.toDate().getMonth() > 5 ? 1 : 0);
  const ageGroup = thisYear - birthYear + 1;
  const schoolAgeGroup =
    18 - (athlete.gradYear - thisYear) + (thisMonth > 6 ? 1 : 0);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#672A20",
        borderRadius: "8px",
        margin: "16px 0",
        padding: "16px 16px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5 style={{ color: "white" }}>
          {athlete.firstName} {athlete.lastName}
        </h5>
        <h6>
          {ageGroup > 19 || (schoolAgeGroup > 18 && ageGroup > 18) ? (
            <span style={{ color: "red" }}>Aged Out</span>
          ) : (
            <span
              style={
                athlete.registrations === undefined ||
                athlete.registrations.filter((a: any) => {
                  console.log(events);
                  console.log(a);
                  const thisE = events.find((e) => e.id === a.id);
                  console.log(thisE);
                  console.log(thisE && thisE.hidden !== true);
                  return thisE && thisE.hidden !== true;
                }).length === 0
                  ? { color: "red" }
                  : athlete.registrations
                      .filter((a: any) => {
                        console.log(events);
                        console.log(a);
                        const thisE = events.find((e) => e.id === a.id);
                        console.log(thisE);
                        console.log(thisE && thisE.hidden !== true);
                        return thisE && thisE.hidden !== true;
                      })
                      .find((a: any) => a.status === "unpaid") !== undefined
                  ? { color: "yellow" }
                  : { color: "green" }
              }
            >
              {athlete.registrations === undefined ||
              athlete.registrations.filter((a: any) => {
                console.log(events);
                console.log(a);
                const thisE = events.find((e) => e.id === a.id);
                console.log(thisE);
                console.log(thisE && thisE.hidden !== true);
                return thisE && thisE.hidden !== true;
              }).length === 0
                ? ""
                : athlete.registrations
                    .filter((a: any) => {
                      console.log(events);
                      console.log(a);
                      const thisE = events.find((e) => e.id === a.id);
                      console.log(thisE);
                      console.log(thisE && thisE.hidden !== true);
                      return thisE && thisE.hidden !== true;
                    })
                    .find((a: any) => a.status === "unpaid") !== undefined
                ? "Awaiting Payment"
                : "Registration Complete"}
            </span>
          )}
        </h6>
      </div>

      {/* <div
        style={{
          margin: "8px 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <small style={{ color: "white" }}>
          <strong>{athlete.dob.toDate().toLocaleDateString()}</strong>
        </small>
        {ageGroup > 19 || (schoolAgeGroup > 18 && ageGroup > 18) ? null : (
          <>
            <small style={{ color: "white" }}>
              Age Group - <strong>{ageGroup}u</strong>
              {ageGroup > 18
                ? "*" + (schoolAgeGroup <= 18 ? " (Eligible for 18u)" : "")
                : ""}
            </small>
            {schoolAgeGroup !== ageGroup ? (
              <small style={{ color: "white" }}>
                {schoolAgeGroup - 6}th Graders Usually Play -{" "}
                <strong>{schoolAgeGroup}u</strong>
              </small>
            ) : null}
          </>
        )}
      </div> */}
      <div style={{ marginTop: "8px" }}>
        {ageGroup > 19 || (schoolAgeGroup > 18 && ageGroup > 18) ? null : (
          <>
            <div style={{ margin: "8px 0" }}>
              {payEvent ? (
                <PayPopup
                  athlete={athlete}
                  evnt={payEvent}
                  setPayEvent={setPayEvent}
                />
              ) : null}
              <RegisteredEvents
                athlete={athlete}
                events={events.filter((e) => e.hidden !== true)}
                setPayEvent={setPayEvent}
              />
            </div>

            <div style={{ marginTop: "8px" }}>
              {registerEvent ? (
                <RegisterPopup
                  athlete={athlete}
                  evnt={registerEvent}
                  setRegisterEvent={setRegisterEvent}
                  addEventRegistration={addEventRegistration}
                />
              ) : null}
              <UpcomingEvents
                athlete={athlete}
                events={events.filter(
                  (e) => e.season === "Either" || e.season === athlete.season
                )}
                setRegisterEvent={setRegisterEvent}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Athlete;
