import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";

import Athletes from "./Athletes/Athletes";
import CreateProfile from "./CreateProfile";
import { User } from "../../models/User";
import { db } from "../../firebase";

type DashProps = {
  user: User;
};

const Dashboard: FC<DashProps> = (props) => {
  const { user } = props;

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<any>(null);
  const [athletes, setAthletes] = useState<DocumentData[]>([]);
  const [events, setEvents] = useState<DocumentData[]>([]);

  async function fetchAthletes() {
    const colRef = collection(db, "users", user.id, "athletes");

    getDocs(colRef).then((querySnapshot) => {
      let tmpAths: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        let tmpAth = doc.data();
        tmpAth.id = doc.id;
        tmpAth.parentID = user.id;
        tmpAths.push(tmpAth);
      });

      fetchRegistrations(tmpAths);
      setLoading(false);
    });
  }

  async function fetchRegistrations(allAths: any) {
    let tmpAths = [...allAths];
    for (let i = 0; i < tmpAths.length; i++) {
      const q = query(
        collection(db, "registrations"),
        where("athlete", "==", tmpAths[i].id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        const newData = {
          id: data.event,
          regID: doc.id,
          status: data.status,
          paidAt: data.paidAt ? data.paidAt.toDate() : null,
        };
        if (tmpAths[i].registrations === undefined) {
          tmpAths[i].registrations = [newData];
        } else {
          tmpAths[i].registrations.push(newData);
        }
      });
    }
    setAthletes(tmpAths);
  }

  async function fetchEvents() {
    const colRef = collection(db, "events");

    getDocs(colRef).then((querySnapshot) => {
      let tmpEvnts: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.data());
        let tmpEvnt = doc.data();
        tmpEvnt.id = doc.id;
        tmpEvnts.push(tmpEvnt);
      });
      setEvents(
        tmpEvnts.sort((a, b) =>
          a.date === b.date
            ? a.title > b.title
              ? -1
              : 1
            : a.date > b.date
            ? 1
            : -1
        )
      );
    });
  }

  async function fetchData() {
    // Fetch Available Events
    fetchEvents();

    // Fetch User Data + Athletes
    const docRef = doc(db, "users", user.id);
    getDoc(docRef).then((snap) => {
      if (!snap.exists()) {
        setLoading(false);
        throw new Error("not-found");
      } else {
        console.log(snap.data());
        setUserData(snap.data());
        fetchAthletes();
      }
    });
  }

  useEffect(() => {
    fetchData();
    return;
  }, []);

  const addEventRegistration = (
    eventID: string,
    athleteID: string,
    regID: string,
    status: string
  ) => {
    let tmpAths = [...athletes];
    let i = tmpAths.findIndex((a) => a.id === athleteID);
    if (tmpAths[i] === undefined) {
      return;
    }
    if (tmpAths[i].registrations === undefined) {
      tmpAths[i].registrations = [
        {
          id: eventID,
          regID,
          status,
          paidAt: status === "paid" ? new Date() : null,
        },
      ];
    } else {
      tmpAths[i].registrations.push({
        id: eventID,
        regID,
        status,
        paidAt: status === "paid" ? new Date() : null,
      });
    }
    setAthletes(tmpAths);
    console.log(tmpAths);
  };

  return (
    <div className="grid">
      {loading ? (
        <>Loading..</>
      ) : userData && userData.firstName ? (
        <Athletes
          user={user}
          data={userData}
          athletes={athletes}
          fetchData={fetchData}
          fetchAthletes={fetchAthletes}
          events={events}
          addEventRegistration={addEventRegistration}
        />
      ) : (
        <CreateProfile user={user} fetchData={fetchData} />
      )}
    </div>
  );
};

export default Dashboard;
